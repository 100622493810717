<template>
  <v-row class="my-5" align="center" justify="center">
    <v-col md="6" sm="12" cols="12">
      <h2 class="white--text">
        <v-icon large class="mr-2" color="white">{{ icon }}</v-icon
        >{{ text }}
      </h2>
    </v-col>
    <v-col class="text-right">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TheTitle",
  props: {
    text: String,
    icon: String,
  },
}
</script>
