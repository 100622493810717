<template>
  <v-container fluid v-if="user">
    <TheTitle :text="`Bem Vindo, ${user.username}!`" :icon="pageIcon"> </TheTitle>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-5">
          <v-chart class="chart" :option="option3" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-5">
          <v-chart class="chart" :option="option" />
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card class="pa-5">
          <v-chart class="chart" :option="option2" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" md="4"> -->
        <!-- <v-card class="pa-5">
          <v-chart class="chart" :option="option4" />
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" md="4">
        <v-card class="pa-5">
          <v-chart class="chart" :option="option5" />
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" md="4">
        <v-card class="pa-5">
          <v-chart class="chart" :option="option6" />
        </v-card>-->
      <!-- </v-col>  -->
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import { mapState } from 'vuex'
import 'echarts'
import VChart from 'vue-echarts'
import TheTitle from '@/components/TheTitle'

export default {
  computed: {
    ...mapState(['user']),
  },
  components: {
    TheTitle,
    VChart,
  },

  data: () => ({
    pageIcon: "mdi mdi-monitor-dashboard",
    objects: [],
    risksList: [],
    status: [],
    dataByPurpose: [],
    finished: false,

    risks: {
      low: {
        total: 0,
        percent: 0,
      },
      medium: {
        total: 0,
        percent: 0,
      },
      high: {
        total: 0,
        percent: 0,
      },
      count: 0,
    },
    legal: [
      { text: 'Consentimento do titular', value: '1' },
      { text: 'Cumprimento de obrigação legal ou regulatória', value: '2' },
      {
        text: 'Execução, pela administração pública, de políticas públicas',
        value: '3',
      },
      { text: 'Realização de estudos por órgão de pesquisa', value: '4' },
      { text: 'Execução de contrato', value: '5' },
      { text: 'Diligências pré-contratuais', value: '6' },
      { text: 'Exercício regular de direitos', value: '7' },
      { text: 'Proteção da vida e da incolumidade física', value: '8' },
      { text: 'Tutela da saúde', value: '9' },
      { text: 'Legítimo Interesse', value: '10' },
      { text: 'Proteção de crédito', value: '11' },
      { text: 'Prevenção à fraude em sistemas eletrônicos', value: '12' },
      { text: 'Segurança do titular em sistemas eletrônicos', value: '13' },
      { text: 'Não atendida', value: '14' },
    ],
    type: [
      { name: 'Coleta', id: '1' },
      { name: 'Uso/Finalidade', id: '2' },
      { name: 'Compartilhamento', id: '3' },
      { name: 'Retenção/Armazenamento', id: '4' },
      { name: 'Descarte', id: '5' },
    ],
    option: {
      title: {
        text: 'Classificação do Riscos',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}%'
      },
      legend: {
        bottom: '10px',
        right: '0',
      },
      series: [
        {
          name: 'Classificação do risco',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [],
        },
      ],
    },
    option2: {
      title: {
        text: 'Riscos por Setor',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {
        data: ['Direct', 'Mail Ad', 'Affiliate Ad', 'Video Ad', 'Search Engine'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: [],
      },
      series: [],
    },
    option3: {
      title: {
        text: 'Acompanhamento de Matriz de Riscos',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        axisLabel: { interval: 0, rotate: 30 },
        data: [],
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: [],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',
          },
        },
      ],
    },
    option4: {
      title: {
        text: 'Dados Sensíveis por Ações em Atividades',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        axisLabel: { interval: 0, rotate: 30 },
        data: [],
      },
      series: [
        {
          data: [],
          type: 'bar',
        },
      ],
    },
    option5: {
      title: {
        text: 'Dados Sensíveis por Tipo de Ação',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        axisLabel: { interval: 0, rotate: 30 },
        data: [],
      },
      series: [
        {
          data: [],
          type: 'bar',
        },
      ],
    },
    option6: {
      title: {
        text: 'Dados Sensíveis por Base Legal',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        axisLabel: { interval: 0, rotate: 30 },
        data: [],
      },
      series: [
        {
          data: [],
          type: 'bar',
        },
      ],
    },
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/report/risk`
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
          this.getRiscos()
        })
        .catch(showError)
    },
    loadStatus() {
      const url = `${baseApiUrl}/report/risk/status`
      axios
        .get(url)
        .then((res) => {
          this.status = res.data
          const color = ['#0D47A1', '#FF9100', '#7E57C2']
          for (let i = 0; i < this.status.length; i++) {
            const element = this.status[i]
            this.option3.xAxis.data.push(element.status)
            this.option3.series[0].data.push({
              value: element.quantidade,
              itemStyle: { color: color[i] },
            })
          }
        })
        .catch(showError)
    },
    getRiscos() {
      for (let i = 0; i < this.objects.length; i++) {
        const element = this.objects[i]
        if (this.getRisk(element.risk) === 'Risco Baixo') {
          this.risks.low.total++
        }
        if (this.getRisk(element.risk) === 'Risco Médio') {
          this.risks.medium.total++
        }
        if (this.getRisk(element.risk) === 'Risco Alto') {
          this.risks.high.total++
        }
      }
      this.risks.low.percent = (this.risks.low.total * 100) / this.objects.length
      this.risks.medium.percent = (this.risks.medium.total * 100) / this.objects.length
      this.risks.high.percent = (this.risks.high.total * 100) / this.objects.length

      this.option.series[0].data = [
        {
          value: this.risks.low.percent.toFixed(2),
          name: 'Baixo',
          itemStyle: { color: '#689F38' },
        },
        {
          value: this.risks.medium.percent.toFixed(2),
          name: 'Médio',
          itemStyle: { color: '#FDD835' },
        },
        {
          value: this.risks.high.percent.toFixed(2),
          name: 'Alto',
          itemStyle: { color: '#880E4F' },
        },
      ]
    },
    getRisk(risk) {
      if (!risk) {
        return ''
      }
      if (risk <= 0.12) {
        return 'Risco Baixo'
      }
      if (risk > 0.12 && risk <= 0.32) {
        return 'Risco Médio'
      }
      return 'Risco Alto'
    },
    loadRisksBySector() {
      const url = `${baseApiUrl}/report/risk/sector`
      axios
        .get(url)
        .then((res) => {
          this.risksList = res.data
          const hs = []
          const ms = []
          const ls = []
          const color = ['#880E4F', '#FDD835', '#689F38'] 

          for (let i = 0; i < this.risksList.length; i++) {
            const element = this.risksList[i]
            this.option2.yAxis.data.push(element.name)
            if (element.risks.high.total && element.risks.high.total > 0) {
              hs.push({
                value: element.risks.high.total,
                itemStyle: { color: color[0] },
              })
            }
            if (element.risks.medium.total && element.risks.medium.total > 0) {
              ms.push({
                value: element.risks.medium.total,
                itemStyle: { color: color[1] },
              })
            }
            if (element.risks.low.total && element.risks.low.total > 0) {
              ls.push({
                value: element.risks.low.total,
                itemStyle: { color: color[2] },
              })
            }
          }

          const high = {
            name: 'Alto',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
            },
            emphasis: {
              focus: 'series',
            },
            data: hs,
          }
          const medium = {
            name: 'Médio',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
            },
            emphasis: {
              focus: 'series',
            },
            data: ms,
          }
          const low = {
            name: 'Baixo',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
            },
            emphasis: {
              focus: 'series',
            },
            data: ls,
          }

          this.option2.series.push(high)
          this.option2.series.push(medium)
          this.option2.series.push(low)
          this.finished = true
        })
        .catch(showError)
    },
    loadDataByPurpose() {
      const url = `${baseApiUrl}/report/data/purpose`
      axios
        .get(url)
        .then((res) => {
          this.dataByPurpose = res.data
          for (let i = 0; i < this.dataByPurpose.length; i++) {
            const element = this.dataByPurpose[i]
            this.option4.yAxis.data.push(element.name)
            this.option4.series[0].data.push({
              value: element.quantity,
              name: element.name,
            })
          }
        })
        .catch(showError)
    },
    loadDataByType() {
      const url = `${baseApiUrl}/report/data/type`
      axios
        .get(url)
        .then((res) => {
          this.dataByType = res.data
          for (let i = 0; i < this.dataByType.length; i++) {
            const element = this.dataByType[i]
            this.option5.yAxis.data.push(this.getTypeName(element.type))
            this.option5.series[0].data.push({
              value: element.quantity,
              name: element.name,
            })
          }
        })
        .catch(showError)
    },
    loadDataByLegal() {
      const url = `${baseApiUrl}/report/data/legal`
      axios
        .get(url)
        .then((res) => {
          this.dataByLegal = res.data
          for (let i = 0; i < this.dataByLegal.length; i++) {
            const element = this.dataByLegal[i]
            this.option6.yAxis.data.push(this.getLegalName(element.legal))
            this.option6.series[0].data.push({
              value: element.quantity,
              name: element.name,
            })
          }
        })
        .catch(showError)
    },
    getLegalName(l) {
      for (let i = 0; i < this.legal.length; i++) {
        const element = this.legal[i]
        if (element.id === l) {
          return element.name
        }
      }
      return ''
    },
    getTypeName(l) {
      for (let i = 0; i < this.legal.length; i++) {
        const element = this.type[i]
        if (element.id === l) {
          return element.name
        }
      }
      return ''
    },
  },
  mounted() {
    this.loadData()
    this.loadRisksBySector()
    this.loadStatus()
    this.loadDataByPurpose()
    this.loadDataByType()
    this.loadDataByLegal()
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
